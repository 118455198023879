<template>
<v-container>
    <v-row>
      <v-col cols="12" class="text-center">
          <header-vue/>
      </v-col>

      <v-col
        class="mb-5"
        cols="12" sm="12" md="6" lg="9"
      >
            <v-card>
                <v-card-title>
                    VERIFY YOUR EMAIL
                </v-card-title>
                <v-card-subtitle>
                    Just to be sure you are not a robot! Enter the CODE sent to <strong>{{email}}</strong>
                </v-card-subtitle>
                <v-card-text>
                    <v-form
                        ref="form"
                        v-model="valid"
                        lazy-validation
                    >
                        <v-row>
                            <v-col md="12" cols="12">
                                <v-text-field
                                v-model="code"
                                :counter="4"
                                :rules="numberRules"
                                label="Verification Code"
                                required
                                ></v-text-field>
                            </v-col> 
                            <v-col md="12">
                                <v-btn
                                :disabled="!valid"
                                color="success"
                                class="mr-4"
                                @click="verify"
                                >
                                Verify
                                </v-btn>
                                <v-btn
                                color="primary"
                                text
                                class="mr-4"
                                @click="resend"
                                >
                                Resend Code
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
            </v-card>
      </v-col>
      <v-col
        class="mb-5"
        cols="12" sm="12" md="6" lg="3"
      >
        <guest-menu-card/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import GuestMenuCard from '../../components/GuestMenuCard.vue'
import axios from 'axios';
import HeaderVue from '../../components/Header.vue';
  export default {
    name: 'VerifyEmail',
    components: {
        GuestMenuCard,
        HeaderVue,
    },
    data: () => ({
        code:null,
        email:sessionStorage.getItem('loggedUser') ? JSON.parse(sessionStorage.getItem('loggedUser')).email : null,
        valid: true,
        numberRules: [
            v => !!v || 'Verification Code is required',
            v => (v && v.length == 4) || 'Code must be 4 digits',
        ],
    }),
    methods: {
        verify () {
            let validation = this.$refs.form.validate()
            if(!validation) {
                return validation
            }
            let loader = this.$loading.show()
            axios.get(process.env.VUE_APP_AUTHURL+'/email/verify?email='+this.email+'&code='+this.code,
            {})
            .then((response) => {
                this.$toast.success('Email is now verified!', {
                    timeout: 5000
                }); 
                sessionStorage.loggedUser =   JSON.stringify(response.data.data)
                this.$store.commit("emailVerified", true);
                this.$router.push('login')
            })
            .catch((error) => {
                this.$toast.error(error.response ? error.response.data.message : 'Network Error, Please try again few minutes', {
                    timeout: 5000
                });
            })
            .finally(() => {
                loader.hide()
            });
        },
        resend () {
            let loader = this.$loading.show()
            axios.get(process.env.VUE_APP_AUTHURL+'/email/verify/code?email='+this.email,
            {})
            .then((response) => {
                this.$toast.success(response.data.message, {
                    timeout: 5000
                });
            })
            .catch((error) => {
                this.$toast.error(error.response ? error.response.data.message : 'Network Error, Please try again few minutes', {
                    timeout: 5000
                });
            })
            .finally(() => {
                loader.hide()
            });
        },
    },
  }
</script>
